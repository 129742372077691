
import { defineComponent } from "vue";

export default defineComponent({
  name: "Subscribe",
  props: {
    formid: {
      type: String,
      default:
        "1FAIpQLSdCSPIzDTIXyc4z7X7UI-fuPt7WMrV8nOfla2Tf9c65NPOxyA/formResponse?entry.950248060",
    },
    response: { type: String, default: "Thank you for subscribing!" },
  },
  data() {
    return { submitted: "", email: "" };
  },
  methods: {
    submit(e: Event): void {
      fetch(
        `https://docs.google.com/forms/d/e/${this.formid}=${this.email}&submit=Submit`,
        {
          method: "GET",
          mode: "no-cors",
        }
      )
        .then(() => {
          this.submitted = "success";
        })
        .catch(() => {
          this.submitted = "error";
        });

      e.preventDefault();
    },
  },
});
