<template>
  <div class="container">
    <h3 class="cover-hero--sub text-center">Page Not Found</h3>
    <h1 class="cover-hero text-center">404</h1>
    <p class="cover-body py-3 text-center">
      Oops, the page you are looking for can't be found!
    </p>
    <div class="py-5"></div>
  </div>
</template>
