<template>
  <nav
    class="navbar sticky-top navbar-expand-lg site-header xxxsite-header--homepage"
  >
    <div class="container">
      <router-link class="navbar-brand" to="/"
        ><img src="./assets/fibn-logo.svg" alt="Fibn Games"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav navbar-right">
          <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
          <router-link class="nav-link" to="/">Home</router-link>
          <router-link class="nav-link" to="/cardgame/buy">Buy</router-link>
          <router-link class="nav-link" to="/cardgame/more-info"
            >More Info</router-link
          >
          <router-link class="nav-link" to="/cardgame/help">Help</router-link>
        </div>
      </div>
    </div>
  </nav>
  <main
    class="flex-shrink-0 flex-grow-1"
    :class="{ 'bg-gray-200': backgroundType == 'gray' }"
  >
    <router-view />
  </main>
  <footer class="footer mt-auto site-footer">
    <div class="container py-3">
      <div class="row">
        <div class="col-12 col-md text-center">
          <small class="d-block mb-0">
            <p class="copyright mb-0">
              &copy; 2021 FibnGames LLC. All Rights Reserved. &nbsp;|&nbsp;
              <router-link class="footer-links" to="/terms">Terms</router-link>
            </p>
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import "styles/main";
</style>

<script>
import "bootstrap/dist/js/bootstrap.bundle.js";
import { defineComponent } from "@vue/composition-api";
import { mapState } from "vuex";

export default defineComponent({
  computed: {
    ...mapState({
      backgroundType: (state) => state.mainBackgroundColor,
    }),
  },
});
</script>
