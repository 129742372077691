import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";
import PageNotFound from "../views/404.vue";
import store from "../store";

const changeToWhiteBackground = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  store.dispatch("changeMainBackgroundColor", "white");
  next();
};

const changeToGrayBackground = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  store.dispatch("changeMainBackgroundColor", "gray");
  next();
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: changeToWhiteBackground,
  },
  {
    path: "/cardgame/more-info",
    name: "More Info",
    component: () =>
      import(
        /* webpackChunkName: "More Info" */ "../views/cardgame/MoreInfo.vue"
      ),
    beforeEnter: changeToWhiteBackground,
  },
  {
    path: "/cardgame/help",
    name: "Help",
    component: () =>
      import(/* webpackChunkName: "Help" */ "../views/cardgame/Help.vue"),
    beforeEnter: changeToWhiteBackground,
  },
  {
    path: "/cardgame/buy",
    name: "Buy",
    component: () =>
      import(/* webpackChunkName: "Buy" */ "../views/cardgame/Buy.vue"),
    beforeEnter: changeToGrayBackground,
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "Terms" */ "../views/Terms.vue"),
    beforeEnter: changeToWhiteBackground,
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "Terms" */ "../views/Events.vue"),
    beforeEnter: changeToWhiteBackground,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
    beforeEnter: changeToWhiteBackground,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(/* to, from, savedPosition */) {
    return { left: 0, top: 0 };
  },
});

export default router;
