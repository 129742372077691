import { createStore } from "vuex";

export default createStore({
  state: {
    mainBackgroundColor: "white",
  },
  mutations: {
    setMainBackgroundColor(state, newColor: string) {
      state.mainBackgroundColor = newColor;
    },
  },
  actions: {
    changeMainBackgroundColor(context, newColor: string) {
      context.commit("setMainBackgroundColor", newColor);
    },
  },
  modules: {},
});
